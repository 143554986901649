<template>
    <div>
        <div class="page-control">  
                    <div class="btn btn-parent" v-if="page>0" title="Go to start">              
                        <button class="btn-child"  v-on:click="goToPage(0)">&laquo;</button>
                    </div>

                    <div class="btn btn-parent" v-if="page>0" title="Previous page">              
                        <button class="btn-child"  v-on:click="goToPage(page-1)">&lsaquo;</button>
                    </div>

                    <div v-if="!page_array.includes(0)>0 && page_array.length>0" class="dots">...</div>

                    <div class="btn btn-parent" v-for="n in page_array" v-bind:key="n" v-bind:class="{current : page === n}">
                        <form v-if="page===n" class="btn-child jump-to" @submit.prevent="jumpToPage">
                            <input type="number"  v-model="jump_to"> 
                            <button type="submit" @click="jumpToPage" v-if="jump_to && jump_to!=page+1" title="Go to page">&Lambda;</button>
                        </form>
                        <button class="btn-child"  v-else v-on:click="goToPage(n)">{{n+1}}</button>                        
                    </div>

                    <div v-if="!page_array.includes(end_page-1)&& page_array.length>0" class="dots">...</div>

                                    
                    <div class="btn btn-parent" v-if="page_array.length>0 && end_page-1!== page" title="Next page">
                        <button class="btn-child" v-on:click="goToPage(page+1)">&rsaquo;</button> 
                    </div>    

                    <div class="btn btn-parent" v-if="page_array.length>0 && end_page-1!== page" title="Go to end">
                        <button class="btn-child" v-on:click="goToPage(end_page-1)">&raquo;</button> 
                    </div>    

                    
        </div>
    </div>
</template>

<script>
export default {
    name: 'PageControl',
    props: {
        total: Number,
        limit: Number,
        offset: Number, 
        show_pages: {
            type: Number,
            default: 5,
        }
    },
    data: function() {
        return {
            jump_to: 1,
        }
    },
    computed: {
        page() {
            return Math.floor(this.offset/this.limit);
        },
        end_page() {
            return Math.ceil(this.total/this.limit); //last page
        },
        page_array() {                
            var end = this.end_page; //last page
            var max_length = Math.min(this.show_pages, end);       

            var start_offset = max_length/2;

            if ( (end-this.page) < max_length/2) {
                //If we are closer than half our max length from end page, push the start index back by however much the end part of the array is compressed
                start_offset += max_length/2-(end-this.page);                
            }   
            
            var start = Math.floor(Math.max(0,this.page - start_offset)); 
                        
            var length = Math.min(max_length, (end-start));         

            var arr = [...Array(length).keys()].map(i=>i+start);     
            return arr;
        }
    },
    watch: {
        page: function(new_val) {
            //Actual pages start at 0
            this.jump_to = new_val+1;
        },
    },
    methods: {
        goToPage(new_page) {
            this.$emit('goToOffset',new_page*this.limit);
        },
        jumpToPage() {
            var new_page = Math.max(0,Math.min(this.jump_to-1, this.end_page-1));
            this.goToPage(new_page);
        }
    }
}
</script>

<style scoped>
    .page-control {
        margin-top: 1.5em;
        white-space: nowrap;
        display: flex;
        gap: 0.5em;
        width: 100%;
        overflow-x: auto;
    }

    .page-control .btn, .dots {
        flex-basis: 0;
        flex-grow: 1;
    }

    .dots{
        margin-top: 1em;
    }
    .current{
        background-color: transparent;
        border-color: #ddd;
        padding:0;
    }
    .btn-child{
        font-size: 16px;
        width: 100%;
        height: 100%;
        padding: 0em 1em;        
    }
    .btn-parent{
        padding: 0em;
    }
    .jump-to{
        display:flex;
        padding: 0em;
    }
    .jump-to input{
        min-width: 50px;
        height: 100%;
        border-bottom:none;
    }
    .jump-to button{
        padding: 0em 0.5em;
        background-color: #eee;
        border: solid 0.5px rgb(170,170,170);        
    }
    
    input[type=number]::-webkit-outer-spin-button,
    input[type=number]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
        
    }

    input[type=number] {
        text-align: center;
        -moz-appearance:textfield;
    }
</style>