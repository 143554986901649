<template>
    <section>
        <div class="main-entity" id="credit-returns-table">
        
            <h1>Credit Returns</h1>
            <button  v-if="!editing" class="btn" @click="goToCreate()">Create OCR</button>                  

            <div>
                <div class="table-wrapper">
                    <table id="credit-table">
                        <thead>
                            <tr>
                                <th>OCR#</th>
                                <th class="inessential"></th>
                                <th class="inessential">Date</th>
                                <th>Account</th>
                                <th>Branch</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="no-ul">
                                <td>
                                    <div class="form-group">
                                        <input type="text" v-model="search_params.id">
                                        <label>OCR#</label>
                                    </div>
                                </td>
                                <td class="inessential"></td>
                                <td class="inessential">
                                    <div class="date-range">
                                        <div class="form-group">
                                            <input type="date" v-model="search_params.dateFrom">
                                            <label>Date From</label>
                                        </div>
                                        <div class="form-group">
                                            <input type="date" v-model="search_params.dateTo">
                                            <label>Date To</label>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="text" v-model="search_params.accountCode">
                                        <label>Account</label>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <select v-model="search_params.branchCode">
                                            <option value=""></option>
                                            <option v-for="branch in branch_array" v-bind:key="branch.id" v-bind:value="branch.id">{{branch.name}}</option>                                            
                                        </select>
                                        <label>Branch</label>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <select v-model="search_params.status">
                                            <option></option>
                                            <option>Open</option>
                                            <option>Pending</option>                        
                                            <option>Completed</option>
                                            <option>Deleted</option>
                                        </select>
                                        <label>Status</label>
                                    </div>
                                </td>
                            </tr>
                            <tr v-for="c_return in saved_returns.slice()" v-bind:key="saved_returns.indexOf(c_return)">
                                <td><a href="javascript:void(0);" v-on:click="goToDetails(c_return)" title="Edit">{{c_return.id || 'Draft'}}</a></td>
                                <td class="inessential">{{c_return.roleCreated===4 ? 'Customer: ': ""}} {{c_return.byAccountCode}}</td>
                                <td class="inessential">{{c_return.date ? c_return.date.split(" ")[0]: ""}}</td>
                                <td>{{c_return.accountCode}}</td>
                                <td>{{c_return.branchName}}</td>
                                <td>{{c_return.status}}</td>                 
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="total-counter">Showing {{this.saved_returns.length}} of {{total_results + drafts}} total results</div>
                <PageControl :total="total_results" :limit="results_per_page" :offset="search_params.offset" @goToOffset="goToOffset($event)"></PageControl>
            </div>
        </div>            
    </section>
</template>


<script>
import api from '@/API/APIManager.js';
const API = new api();

import {debounce} from '@/helpers.js';

import PageControl from '@/components/PageControl.vue'

export default {
    components: {
        PageControl
    },
    data() {
        return {
            account_details:{
               accountCode:"",
               email:"",
               customer_ref: "", 
               status:"Draft",
            },
            saved_returns: [], 
            online: true,
            editing: false,
            page:0,
            drafts: 0,
            total_results: 0,
            results_per_page:20,
            error:"",
            search_params: {
                limit: this.results_per_page,
                offset: 0,
                id: "",
                accountCode: "",
                branchCode: "",
                status:"Open",
                dateTo: "",
                dateFrom: "",
                },
            branch_array: [],
            }
   
        },
        computed:{
            
        },
        watch: {
            search_params: {
                deep:true,

                handler: debounce(async function(){
                    //Do the refresh. Runs on pageload too.                   
                    await this.updateViewFromIndexDb();                    
                },100),
            },            
            total_results: function(newVal,oldVal) {
                //Move to page one after search
                 //Allow total results to flex a little to account for slight number deviations on back-end.
                if (Math.abs(newVal - oldVal) > 5) {
                    this.goToOffset(0);
                }                
            }
        },
        methods:{
            goToDetails: function(c_return) {
                if (c_return.id) {
                    this.$router.push({
                    name: 'View Return',
                    params: {
                        invoice_id: c_return.id,
                    }
                    })
                } else if (c_return.key){
                    this.$router.push({
                    name: 'View Draft Return',
                    params: {
                            key: c_return.key,
                        }
                    })
                }
                
            },     
            goToCreate: function() {
                this.$router.push({
                    name:'Create New Return',
                })
            },     
            async initiateIndexDb() {
                if (!('indexedDB' in window)) {
                    console.log("Browser does not support indexedDB")                    
                    return;
                }                
                var dbPromise = await window.indexedDB.open('parts_manager_db', this.$store.state.local_db_version);
                dbPromise.onupgradeneeded = (event)=> {
                    var db = event.target.result;
                    if (!db.objectStoreNames.contains('returns')) {
                        db.createObjectStore('returns',{autoIncrement:true});                        
                    }
                }                             
            },

            async updateViewFromIndexDb() {
                //Get from API first
                try{
                    this.saved_returns = [];
                    var api_returns = await API.parts_manager.getReturns(this.search_params);
                    this.total_results = api_returns.total;
                    this.saved_returns = api_returns.rows;
                } catch(error) {
                    console.log(error);
                }               
                                
                //Get from local database.
                var dbPromise =  window.indexedDB.open('parts_manager_db', this.$store.state.local_db_version);

                dbPromise.onsuccess = async (event) =>{                  
                
                    var db = event.target.result;
                    var credit_returns = db.transaction('returns',IDBTransaction.READ_ONLY).objectStore('returns').openCursor();

                    credit_returns.onerror = () => {
                        console.log("Error fetching results");
                    }

                    this.drafts = 0;

                    credit_returns.onsuccess = async (event) =>{
                        let cursor = event.target.result;
                        
                        if(cursor && (this.search_params.id==="")) {
                            let key = cursor.primaryKey;
                            let value = cursor.value;     
                            cursor.continue();                    
                            this.drafts++;  

                            if ((this.search_params.accountCode ==="" || this.search_params.accountCode == value.accountCode)) {                              
                                if (this.page === 0){                                    
                                    this.saved_returns.unshift({key,...value}); 
                                }                      
                            }                                                                                   
                        }
                    }                                 
                }                
            },

            resetSearch() {
                this.search_params= {
                    limit: this.results_per_page,
                    offset: 0,
                    id: "",
                    accountCode: "",
                    branchCode: "",
                    status:"",
                    dateTo: "",
                    dateFrom: "",
                }
            },
            goToPage(go_to_page){
                this.page = go_to_page;
                this.search_params.offset = go_to_page*this.results_per_page;
            },
            goToOffset(offset) {
                this.page = offset/this.results_per_page;                

                this.search_params.offset = offset;
            }
        },
        async created(){          
                this.search_params.limit = this.results_per_page;                                
                
                this.search_params.branchCode=this.$store.state.user.branch;

                var response= await API.parts_manager.getBranchList();
                this.branch_array = response.data;

                await this.initiateIndexDb();    
        }
}
</script>

<style scoped>
    .date-range{
        display: inline-flex;
    }

    .date-range .form-group {
        width: 45%;
    }
    .total-counter {
        margin-top: 1.5em;
    }
    .page-control {
        margin-top: 1.5em;
        white-space: nowrap;
        display: flex;
        gap: 0.5em;
    }
    .current{
        background-color: transparent;
        border-color: #ddd;
    }


</style>